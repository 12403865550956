import React from "react"
import "../style/contact.css"
import Layout from "../components/layout"
import Helmet from "react-helmet"

class brochurePage extends React.Component {
    constructor(){
        super()
        this.getCookie = this.getCookie.bind(this);
      }
    getCookie = (cname) => {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    } 
    brochure_mail(){
        var name = document.getElementById("nri_name").value.trim();
        var phone = document.getElementById("nri_phone").value.trim();
        var email = document.getElementById("nri_email").value.trim();
        // UTM DATA
        // Populate Cookies data
        var utmSource = this.getCookie("sourcey");
        var utmMedium = this.getCookie("mediumy");
        var utmCampaign = this.getCookie("campaigny");
        var utmTerm = this.getCookie("termy");
        var utmContent = this.getCookie("contenty");
        // Email Format
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (name == "") {
            alert("Enter you Name");
        }

        else if (reg.test(email) == false) {
            alert("Enter a valid Email");
        }

        else if (phone.toString().length != 10) {
            alert("Enter a valid Phone number");
        }

        else {
            var HttpClient = function () {
                this.get = function (aUrl, aCallback) {
                    var anHttpRequest = new XMLHttpRequest();
                    anHttpRequest.onreadystatechange = function () {
                        if (anHttpRequest.readyState == 4 && anHttpRequest.status == 200)
                            aCallback(anHttpRequest.responseText);
                    }
                    anHttpRequest.open("GET", aUrl, true);
                    anHttpRequest.send(null);
                }
            }
            var theurl = 'https://script.google.com/macros/s/AKfycbwdipSQceH43wj8gRYra1pJi-UNAksNNT3KQmiighGUIm4Vysm-m7u-/exec?name=' + name + "&email=" + email + "&phone=" + phone + "&utmSource=" + utmSource + "&utmMedium=" + utmMedium + "&utmContent=" + utmContent + "&utmTerm=" + utmTerm + "&utmCampaign=" + utmCampaign;
            var client = new HttpClient();
            client.get(theurl, function (response) {
                var response1 = JSON.parse(response);
                //document.getElementById("print").innerHTML = response1.status ;
            }); 
            window.location.replace("/brochure_thankyou");
        }
    }
    render() {
        return (
            <Layout>
                <br /><br /><br /><br />

                {/* IMAGE CONTACT */}
                <div className="img-bg" style={{ backgroundImage: "url('../Images/misc.png')" }}>
                    <div className="row">
                        <div className="col-md-12 text-center text-white pt-5 pb-5">
                            <h5 >DOWNLOAD FREE BROCHURE</h5>
                        </div>
                    </div>
                    <div className="container pl-5 pr-5 pb-5">
                        <div className="row">
                            <div className="col-md-3 text-center">
                                <input type="text" placeholder="NAME" className="s-con" id="nri_name" />
                            </div>
                            <div className="col-md-3 text-center">
                                <input type="text" placeholder="E-MAIL" className="s-con" id="nri_email" />
                            </div>
                            <div className="col-md-3 text-center">
                                <input type="number" placeholder="PHONE NUMBER" className="s-con" id="nri_phone" />
                            </div>
                            <div className="col-md-3 text-center">
                                <button className="btn btn-bro w-100" style={{ padding: "10px 30px" }} onClick={()=>this.brochure_mail()}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pl-5 pr-5 pt-5 pb-5">
                    <div className="row ">
                        <div className="col-md-4 pb-3">
                            <p className="c-p"><b>CORPORATE OFFICE</b></p>
                            <p className="c-p">Address : Sy.No.108/2, Millenia building,1st Main, MSR Layout, Munnekollala Village, Marathahalli Outer Ring Road, Bangalore 560037</p>
                        </div>
                        <div className="col-md-4 pb-3">
                            <p className="c-p"><b>GET IN TOUCH</b></p>
                            <p className="c-p"><a href="tel:+91 8431 999 444" className="c-p">Phone : +91 8431 999 444</a>
                                <a href="mailto:enquiries@realvaluehomes.in" className="c-p">Email : enquiries@realvaluehomes.in</a>
                                <a href="https://sumadhuragroup.com" target="_blank" className="c-p">Website : www.sumadhuragroup.com/</a></p> </div>
                        <div className="col-md-4 pb-3">
                            <p className="c-p"><b>CONNECT WITH US</b></p>
                            <a href="https://www.facebook.com/Aspire-Series-A-Sumadhura-Venture-106528511410714" target="_blank">
                                <span className="c-social">
                                    <i className="fa fa-facebook-official" aria-hidden="true"></i>
                                </span></a>
                            <a href="https://www.instagram.com/aspire__series/" target="_blank">
                                <span className="c-social">
                                    <i className="fa fa-instagram" aria-hidden="true"></i>
                                </span></a>
                            <a href="https://www.youtube.com/c/Sumadhuragroupbuilders " target="_blank">
                                <span className="c-social">
                                <i className="fa fa-youtube-play" aria-hidden="true"></i>
                            </span></a>
                            <a href="https://twitter.com/teamsumadhura " target="_blank">
                                <span className="c-social">
                                <i className="fa fa-twitter" aria-hidden="true"></i>
                            </span></a>
                            <a href="https://www.linkedin.com/company/sumadhura-infracon-pvt--ltd " target="_blank">
                                <span className="c-social">
                                <i className="fa fa-linkedin" aria-hidden="true"></i>
                            </span></a>
                        </div>
                    </div>
                </div>
		<script src="https://www.kenyt.ai/botapp/ChatbotUI/dist/js/bot-loader.js" type="text/javascript" data-bot="25390341"></script>
            </Layout>
        )
    }
}
export default brochurePage
